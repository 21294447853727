<ion-content id="welcome">
  <fab-buttons-langs></fab-buttons-langs>

  <ion-grid fixed style="height: 295px; background-image: url('../../assets/icon/mainpage-icons/background.jpg');"
    class="width__100">


    <app-header-logo className="safe--area"
      *ngIf="!this.myService.appInManutenzione && !this.myService.isVersionObsoleta"></app-header-logo>


  </ion-grid>


  <ion-grid fixed class="width__100"
    style="height: calc(100% - 295px);background: linear-gradient(180deg, rgb(255 243 213) 0%, rgb(233 121 33) 100%);">

    <div *ngIf="!this.myService.isVersionObsoleta && !this.myService.appInManutenzione" class="display__flex "
      style="height: calc(100% - 60px);">

      <div class="menu__items__container">

        <div (click)="presentPopoverLogin();" class="menu__items__container__item"
          style=" border-top: none;     border-left: none;">
          <img class="main__menu__icon" src="../../assets/icon/mainpage-icons/faccetta.png">
          <p class="text__bold">Login</p>
        </div>


        <div class="flex__direction__column menu__items__container__item" style=" border-bottom: none;
        border-left: none;">


          <span
            *ngIf="this.myService.isLoginGoogleiOSAvailable || this.myService.isLoginFbIosAvailable || this.myService.isLoginAppleiOSAvailable">


            <div class="menu__items__container__items__row menu__items__ios">

              <!--       INIZIO  FACEBOOK LOGIN IOS  -->

              <div *ngIf="this.myService.isLoginFbIosAvailable" (click)="fbLoginWeb(); " class=""
                style=" border-bottom: none; border-left: none;">
                <img class="main__menu__icon" src="../../assets/icon/servizi/fb.png">
              </div>

              <!--       FINE  FACEBOOK LOGIN IOS  -->

              <!--         GOOGLE LOGIN  IOS  -->

              <div *ngIf="this.myService.isLoginGoogleiOSAvailable" (click)="googleSignInWeb(); " class=""
                style=" border-bottom: none; border-left: none;">
                <img class="main__menu__icon" src="../../assets/icon/servizi/google.png">
              </div>
              <!--         FINE GOOGLE LOGIN IOS  -->
            </div>
            <p class="text__bold">Social Login</p>

          </span>
          <span *ngIf="!this.myService.isLoginFbAndroidAvailable && !this.myService.isLoginGoogleAndroidAvailable"
            (click)="goBackToWelcomePage()">

            <div class="menu__items__container__items__row menu__items__ios">
              <p style="font-size: 30px; margin: 0">
                <ion-icon name="information-circle-outline"></ion-icon>
              </p>
            </div>
            <p class="text__bold">Info</p>
          </span>
        </div>
      </div>

      <div class="menu__items__container">

        <div (click)="signup()" class="menu__items__container__item" style=" border-top: none; border-right: none;">
          <img class="main__menu__icon" src="../../assets/icon/mainpage-icons/registrazione.png">
          <p class="text__bold">{{this.myService.registrazione}}</p>
        </div>
        <div (click)="procediComeOspite()" class="menu__items__container__item"
          style="border-bottom: none; border-right: none;">
          <img class="main__menu__icon" src="../../assets/icon/mainpage-icons/guest.png">
          <p class="text__bold">{{this.myService.proseguiGuestLabel}}</p>
        </div>

      </div>
    </div>
  </ion-grid>
  <ion-grid class="welcome-page__content__container__vers__obsoleta ">
    <span class="version--number">Vers. 4.3.004</span>
  </ion-grid>
</ion-content>
<tab-component *ngIf="!this.myService.isVersionObsoleta && !this.myService.appInManutenzione"
  class="display__flex justify__center "></tab-component>