import { Injectable } from '@angular/core';
import { SpiaggiaService } from '../../utils/spiaggia.service';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { HttprequestService } from '../../httprequest.service';
import { StorageService } from '../../storage-service.service';
import { AlertService } from '../../alert-service.service';
import { UserService } from '../../utils/user.service';

@Injectable({
  providedIn: 'root'
})
export class FbloginWebService {


  constructor(
    private myService: SpiaggiaService,
    private http: HttprequestService,
    private storage: StorageService,
    private userService: UserService,
    private alertService: AlertService,

  ) {
    this.initFb();

  }



  async initFb() {
    await FacebookLogin.initialize({ appId: '1394474224017863' });
  }


  async fbLogin() {

    /*    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
       const result = await <any>FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
       if (result && result.accessToken) {
   
         this.http.doGet('https://graph.facebook.com/me?fields=name,email,picture.width(200).height(200)&access_token=' + result.accessToken.token).subscribe(response => {
           let data = response;
           this.loginFacebook(result.accessToken, data.email, data.id);
         });
       } */
    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];

    const result = await (<any>(
      FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    ));
    const data = await FacebookLogin.getProfile<{
      email: string;
      id: string;
    }>({ fields: ['email', 'id'] });
    /*  console.log(result);
     console.log(data); */
    if (result.accessToken) {
      // Login successful.
      this.loginFacebook(result.accessToken, data.email, data.id);
      /*       console.log(`Facebook access token is ${result.accessToken.token}`); */
    }


  }

  loginFacebook(res, email, userId) {
    this.myService.mostraLoader();

    let postData = {
      "utente": {
      },
      "credentials": {
        "identifier": userId,
        "secret": res.token
      }

    }

    this.http.doPostApplicationTextAll(this.myService.serviziRest.loginFb, postData)
      .subscribe(res => {

        this.myService.tokenValue = res;
        this.userService.isUserLoggedIn = true;
        this.userService.isUserLoggedInFacebook = true;
        this.storage.set('token', res);
        this.storage.set('username', email);
        this.getDatiUtenteFb(email);
        if (this.myService.loading) {
          this.myService.loading.dismiss();
        }
      }, error => {
        if (this.myService.loading) {
          this.myService.loading.dismiss();
        }
        if (error.status == 0) {
          this.alertService.alertAddressError();
        } else if (error.status == 400 || error.status == 401 || error.status == 403) {
          this.alertService.alertWrongCredentials();
        } else {
          this.alertService.alertGenericError();
        }
      });

  }

  getDatiUtenteFb(email) {

    this.userService.getDatiUtente(email, 'facebookLogin');

  }


}
